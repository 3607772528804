nav {
  z-index: 1;
  width: 100vw;
  height: 5rem;
  display: grid;
  place-items: center;
  background-color: var(--color-primary);
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 1rem 1.5rem hsla(var(--primary-hue), 68%, 42%, 20%);
  transition: var(--transition);
}

.nav__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__logo {
  width: 5rem;
  overflow: hidden;
}

.nav__color_palette {
  width: 3rem;
  overflow: hidden;
  cursor: pointer;
}

.nav__menu {
  display: flex;
  gap: 3.5rem;
}

@media screen and (max-width: 1024px) {
  .nav__menu {
    display: none;
  }
}

header {
  margin-top: 5rem;
  height: calc(100vh - 5rem);
  display: grid;
  place-items: center;
  background-color: var(--color-white);
  transition: var(--transition);
}

.header__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.header__profile {
  width: 22rem;
  border-radius: 9rem 9rem 2rem 2rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.header__container p {
  width: 35rem;
  text-align: center;
  margin: 0.5rem 0 2rem;
}

.header__cta {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}

.header__socials {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  left: 0;
  bottom: 0;
}

.header__socials a {
  display: grid;
  place-items: center;
  width: 1.8rem;
  height: 1.8rem;
  background-color: var(--color-light);
  color: var(--color-black);
  border-radius: var(--border-radius-3);
}

.header__socials a:hover {
  background: var(--color-primary);
  color: var(--color-white);
  transform: translateX(0.5rem);
}

@media screen and (max-width: 1024px) {
  header {
    margin-top: 0;
    height: 100vh;
  }
}

@media screen and (max-width: 600px) {
  .header__profile {
    width: 14rem;
    max-width: 60%;
  }

  .header__container p {
    width: 100%;
  }

  .header__cta {
    gap: 1rem;
  }

  .header__socials {
    display: none;
  }
}

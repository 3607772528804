.portfolio__projects {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

.project__project_image {
  width: 100%;
  height: fit-content;
  min-height: 8rem;
  max-height: 15rem;
  background: var(--color-light);
  border-radius: var(--border-radius-2);
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.portfolio__project p {
  margin: 0.5rem 0 2rem;
}

.portfolio__project_cta {
  display: flex;
  gap: 1rem;
}

.portfolio__project:hover .btn.sm {
  border: var(--color-white);
}

.portfolio__project:hover .btn.sm.primary {
  background: transparent;
  color: var(--color-primary);
  border-color: var(--color-primary);
}

/* media query for mid sized screens */
@media screen and (max-width: 1024px) {
  .portfolio__projects {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

/* media query for small sized screens */
@media screen and (max-width: 600px) {
  .portfolio__projects {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
}

.contact__container {
  width: fit-content;
  display: flex;
  justify-content: center;
  gap: 3rem;
}

.contact__container a {
  display: grid;
  width: 4.5rem;
  aspect-ratio: 1/1;
  background-color: var(--color-primary);
  place-items: center;
  font-size: 2rem;
  border: 2px solid transparent;
  border-radius: var(--border-radius-1);
}

.contact__container a:hover {
  background: transparent;
  border-color: var(--color-primary);
  color: var(--color-primary);
  transform: translateY(-0.5rem);
}

/* phone media queries */
@media screen and (max-width: 600px) {
  .contact__container {
    gap: 1.5rem;
  }
}
